$(window).on("hashchange", page_changed);
$(window).on("load", page_changed);

function page_changed(){
    frappe.after_ajax(function(){
        let route = frappe.get_route();
        if (frappe.router.current_route && frappe.breadcrumbs.current_page() in frappe.breadcrumbs.all){
            let currentDT = frappe.breadcrumbs.all[frappe.breadcrumbs.current_page()].doctype
            if (["ToDo", "Email", "Newsletter", "Communication"].indexOf(currentDT) > -1){
                frappe.breadcrumbs.add({
                  "module": "PCG Web",
                  "doctype": currentDT
                })

            }
        }
    }) 
}
