frappe.provide("pcg_web.utils");

function add_search_filter(et) {
    let customer = et.doc.customer_link;
    let address = et.doc.address_link;
    let check = frappe.call({
        method: 'frappe.client.get_value',
        args: {
            'doctype': 'PCG Delivery Address',
            'filters': {'name': address},
            'fieldname': 'customer_link'		
        },
        callback: (r) => {
            if (!r.exc) {
                return r.message.customer_link;					
            }
        }
    });
  
    if (customer && (!address || customer != check)) {
  
        if (customer != check) {
            et.set_value("address_link", "");
        }
  
        frappe.call({
            method: 'frappe.client.get_list',
            args: {
                'doctype': 'PCG Delivery Address',
                'filters': {'customer_link': customer},
                'fields': [
                    'name'
                ]
            },
            callback: (r) => {
                if (!r.exc) {
                    et.get_field("address_link").get_query = function() {
                        return {
                            filters: [
                                ["PCG Delivery Address", "customer_link", "in", customer]
                            ]
                        };
                    }
                    if(r.message.length == 1) {
                        et.set_value("address_link", r.message[0].name);
                    } 
                }
            }
        });	
        
    } else {
        et.get_field("address_link").get_query = function() {
            return; 
        }
    }
}

pcg_web.utils.add_search_filter = add_search_filter;