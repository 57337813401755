

frappe.provide("pcg_web.actions");

// pcg_web.action.pcg_item 


pcg_web.actions = function(){
    return {
        ask_for_price_change(listview_or_frm){
            // listview is optional. if not given, the 
            let listview = listview_or_frm instanceof frappe.views.ListView ? listview_or_frm : undefined;
            let frm = listview_or_frm  instanceof frappe.ui.form.Form ? listview_or_frm : undefined;
            
            const dialog_opts = {
                fields: [
                    {
                        label: __('Select action'),
                        fieldname: 'price_option',
                        fieldtype: 'Select',
                        options: [__('absolut_price'), __('percent_price'), __('absolut_price_purchase'), __('percent_price_purchase'), __('round_price'), __('Minimum Marge')],
                        default: __('absolut_price')
                    },
                    {
                        label: __('price_amount'),
                        fieldname: 'increase_price',
                        fieldtype: 'Int',
                        mandatory_depends_on: "eval:doc.price_option !== __('Minimum Marge')",
                        depends_on: "eval:doc.price_option !== __('Minimum Marge')",
                    }],
                title: __('Price management'),
                primary_action_label: __('Submit'),
                primary_action(values, filters, expected_total_count, event_uuid){
                    filters = listview ? filters : [[frm.doc.doctype, "name", "=", frm.doc.name]]
                    frappe.call("pcg_web.pcg_warehouse.doctype.pcg_item.pcg_item.increase_base_price_all_action", {
                        filters: filters,
                        options: values.price_option,
                        value: values.increase_price,
                        event_uuid: event_uuid,
                    }).then(res => {
                        dialog.hide();
                    });
                }
            }

            const handle_respnse = function(response_data){

            };

            let dialog = pcg_web.mass_operation_or_singleton_action_dialog(dialog_opts, listview, handle_respnse);
            return dialog;
        },
        ask_for_expired_offerings(listview_or_frm){
            let listview = listview_or_frm instanceof frappe.views.ListView ? listview_or_frm : undefined;
            let frm = listview_or_frm  instanceof frappe.ui.form.Form ? listview_or_frm : undefined;

            const dialog_opts = {
                fields: [],
                title: __('Expired Offerings'),
                primary_action_label: __('Submit'),
                primary_action(values, filters){
                    filters = listview ? filters : [[frm.doc.doctype, "name", "=", frm.doc.name]];
                    frappe.call("pcg_web.pcg_warehouse.doctype.pcg_item.pcg_item.on_check_for_expired_offerings", {
                        filters: filters
                    }).then(res => {
                        dialog.hide();
                        });
                }
            }

            const handle_respnse = function(response_data){

            };

            let dialog = pcg_web.mass_operation_or_singleton_action_dialog(dialog_opts, listview, handle_respnse);
            return dialog;
        },
        update_item_titles_by_regex(listview_or_frm){
            let listview = listview_or_frm instanceof frappe.views.ListView ? listview_or_frm : undefined;
            let frm = listview_or_frm  instanceof frappe.ui.form.Form ? listview_or_frm : undefined;

            const dialog_opts = {
                fields: [
                    {
                        fieldtype: 'HTML',
                        fieldname: 'description',
                        options: `<p class="alert alert-warning">${__("pcg_item_action_dialog_description")}</p>\n`
                    },
                    {
                        label: __('Regex Pattern'),
                        fieldname: 'regex_pattern',
                        fieldtype: 'Data',
                        reqd: true,
                        description: __('Enter the regular expression pattern to match the titles that need updating.')
                    },
                    {
                        label: __('Replacement String'),
                        fieldname: 'replacement_string',
                        fieldtype: 'Data',
                        description: __('Enter the string that will replace the matched patterns in the titles.')
                    }
                    ],
                    title: __('Update Item Title'),
                primary_action_label: __('Submit'),
                primary_action(values, filters){
                    filters = listview ? filters : [[frm.doc.doctype, "name", "=", frm.doc.name]];
                    frappe.call("pcg_web.pcg_warehouse.doctype.pcg_item.pcg_item_list_actions.list_action_update_item_titles_by_regex", {
                        filters: filters,
                        regex_pattern: values.regex_pattern,
                        replacement_string: values.replacement_string
                        }).then(res => {
                            dialog.hide();
                            });
                        }
                    }
                            
                            const handle_respnse = function(response_data){};

            let dialog = pcg_web.mass_operation_or_singleton_action_dialog(dialog_opts, listview, handle_respnse);
            return dialog;
        },
        update_item_price_hints(listview_or_frm){
            let listview = listview_or_frm instanceof frappe.views.ListView ? listview_or_frm : undefined;
            let frm = listview_or_frm  instanceof frappe.ui.form.Form ? listview_or_frm : undefined;

            const dialog_opts = {
                fields: [
                    {
                        fieldtype: 'HTML',
                        fieldname: 'description',
                        options: `<p class="alert alert-warning">${__("pcg_item_price_hint_dialog_description")}</p>\n`
                    }
                    ],
                    title: __('Update Item Title'),
                primary_action_label: __('Submit'),
                primary_action(values, filters){
                    filters = listview ? filters : [[frm.doc.doctype, "name", "=", frm.doc.name]];
                    frappe.call("pcg_web.pcg_warehouse.doctype.pcg_item.pcg_item_list_actions.list_action_update_item_price_hint", {
                        filters: filters
                        }).then(res => {
                            dialog.hide();
                            });
                        }
                    }
                            
            const handle_respnse = function(response_data){};

            let dialog = pcg_web.mass_operation_or_singleton_action_dialog(dialog_opts, listview, handle_respnse);
            return dialog;
        },

    };
}();

pcg_web.actions.pcg_invoice = {
        create_e_invoice(listview_or_frm){
            let listview = listview_or_frm instanceof frappe.views.ListView ? listview_or_frm : undefined;
            let frm = listview_or_frm  instanceof frappe.ui.form.Form ? listview_or_frm : undefined;

            const dialog_opts = {
                fields: [
                    {
                        fieldtype: 'HTML',
                        fieldname: 'description',
                        options: `<p class="alert alert-warning">${__("pcg_web_action_invoice_create_e_invoice_description")}</p>\n`
                    },
                    {
                        label: __('Type'),
                        fieldname: 'type',
                        fieldtype: 'Select',
                        options: ['XRechnung', 'ZUGFeRD'],
                        default: 'XRechnung',
                        description: __('pcg_web_action_invoice_create_e_invoice_type')
                    }],
                title: __('Electronic invoice'),
                primary_action_label: __('Submit'),
                primary_action(values, filters, expected_total_count, event_uuid){
                    filters = listview ? filters : [[frm.doc.doctype, "name", "=", frm.doc.name]];
                    frappe.call("pcg_web.pcg_fulfillment.doctype.pcg_invoice.pcg_invoice_list_action.request_create_e_invoices", {
                        filters: filters,
                        type: values.type,
                    }).then(result => {
                            let message = result.message;
                            if (message && message[0] && message[0][0] == false){
                                if (message[0][1] == 'invoice'){
                                    let msg = '';
                                    for (const [key, value] of Object.entries(message[1])) {
                                        msg += key + ': ' + value.join(', ') + '<br>';
                                    }
                                    frappe.msgprint(
                                        {
                                            title: __('Missing fields in Customer'),
                                            message: msg,
                                            primary_action_label: __('To Invoices'),
                                            primary_action:{
                                                async action(values) {
                                                    await listview.filter_area.filter_list.clear_filters()
                                                    await listview.filter_area.filter_list.add_filter('PCG Invoice', 'name', 'in', Object.keys(message[1]), true)
                                                    await listview.filter_area.filter_list.apply()
                                                }
                                            }
                                        });
                                }
                                else if(message[0][1] == 'settings'){
                                    let msg = message[1].join(', ')
                                    frappe.msgprint(
                                        {
                                            title: __('Missing fields in System Settings'),
                                            message: msg,
                                            primary_action_label: __('PCG System Settings'),
                                            primary_action:{
                                                action(values) {
                                                    frappe.set_route("pcg-system-settings");
                                                }
                                            }
                                        });
                                }
                            }else{
                                frappe.msgprint(
                                    {
                                        title: __('Electronic Invoice'),
                                        message: __('pcg_electronic_invoice_will_be_saved'),
                                    });
                            }
                        }
                    );
                    dialog.hide();
                }
            }

            const handle_respnse = function(response_data){

                console.log("response_data");
                console.log(response_data);
                console.log(response_data);
                console.log(response_data);

            };

            let dialog = pcg_web.mass_operation_or_singleton_action_dialog(dialog_opts, listview, handle_respnse);
            return dialog;
        }
}