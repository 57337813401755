frappe.provide("pcg_web.ui")



pcg_web.ui.checkbox = (function () {

    function make_checkbox(doctype, title, change_handler, state_getter){
        // in change_handler, `this` will be bound to the checkbox input field.
        // `this.checked` will then give the state, or make the change_handler handle an `state` input argument
        // which will give 1 if the checkbox is checked, and 0 otherwise
        if (typeof (state_getter) === "function") {
            frappe.ui.form.handlers[doctype]["refresh"].push(function () {
                //When the state getter is defined it is triggered when the form is saved 
                $checkbox.prop("checked", state_getter(title, $checkbox_wrapper));
            });
        }
        let checkbox_html = `
        <div class="form-group frappe-control input-max-width" data-fieldtype="Check" data-fieldname="${title}" title="${title}">
        <div class="checkbox">
            <label>
                <span class="input-area"><input type="checkbox" autocomplete="off" class="input-with-feedback" data-fieldtype="Check" data-fieldname="${title}" placeholder=""></span>
                <span class="disp-area" style="display: none;"><i class="fa fa-square disabled-check"></i></span>
                <span class="label-area small">${__(title)}</span>
            </label>
            <p class="help-box small text-muted"></p>
        </div>
        </div>`;
        let $checkbox_wrapper = $(checkbox_html);
        let $checkbox = $checkbox_wrapper.find("input[type='checkbox']");

        $checkbox.prop("checked", state_getter(title, $checkbox_wrapper));
        $checkbox.change(function(){return change_handler.bind(this)(this.checked);})
        $checkbox_wrapper.childtable_update_triggered = function(){
            $checkbox.prop("checked", state_getter(title, $checkbox_wrapper));
        }
        return $checkbox_wrapper;
    }
    return {
        make_checkbox: make_checkbox
    }
})()

 
pcg_web.ui.toggle_table_with_checkboxes = (function () {

    function render_as_toggable(doctype, frm, child_table, toggles) {

        if (typeof(frm.doc[child_table]) === "undefined"){
            frm.doc[child_table] = [];
        }

        //Enhancer function for single checkbox
        function make_checkbox_with_handlers(entry) {
            function is_in_childtable(entry) {
                return typeof(frm.doc[child_table]) !== "undefined" && frm.doc[child_table].filter(e => e.title === entry).length !== 0;
            }
            function on_change(state) {
                if (this.checked) {
                    // ensure_in_childtable
                    if (!is_in_childtable(entry)){
                        frm.add_child(child_table, {title: entry});
                    }
                } else {
                    // ensure_NOT_in_childtable
                    let child_table_without_item = frm.doc[child_table].filter(el => el.title !== entry)
                    if (frm.doc[child_table].length !== child_table_without_item.length){
                        frm.doc[child_table] = child_table_without_item;
                        frm.dirty();
                    }
                }
                frm.fields_dict[child_table].refresh();
            }
            
            let checkbox = pcg_web.ui.checkbox.make_checkbox(doctype, entry, on_change, is_in_childtable)
            return checkbox;
            // This would be so much easier and would save all the childtable_update_triggered hassle...
            // But I didn't get it to work
            // if (typeof(frappe.ui.form.handlers["PCG Item"][child_table + "_add"]) === "undefined"){
            // 	frappe.ui.form.handlers["PCG Item"][child_table + "_add"] = [];
            // }
            // if (typeof(frappe.ui.form.handlers["PCG Item"][child_table + "_remove"]) === "undefined"){
            // 	frappe.ui.form.handlers["PCG Item"][child_table + "_remove"] = [];
            // }
            // frappe.ui.form.handlers["PCG Item"][child_table + "_add"].push(function(){
            // 	checkbox.childtable_update_triggered();
            // })
            // frappe.ui.form.handlers["PCG Item"][child_table + "_remove"].push(function(){
            // 	checkbox.childtable_update_triggered();
            // })
        }

        //Create HTML
        let $tbl = frm.fields_dict[child_table].$wrapper;
        let $checkbox_node_list = toggles.map((toggle) => make_checkbox_with_handlers(toggle));
        let half_amount = parseInt($checkbox_node_list.length/2);
        let $right_boxes =  $checkbox_node_list.slice(0,half_amount);
        let $left_boxes =  $checkbox_node_list.slice(half_amount);
        let $right_column = $("<div class='form-column col-sm-6'></div>").append($right_boxes);
        let $left_column = $("<div class='form-column col-sm-6'style='border-bottom: none;'></div>").append($left_boxes);
        let $boxes_div = $(`<div class="form-group frappe-control input-max-width ${child_table}_toggles" data-fieldtype="Check" title="Specify more than required"></div`).append($left_column).append($right_column);
        $boxes_div.insertBefore($tbl.parent().parent()) // .find(".form-group > .clearfix"));
  
        // Changes the child table based on checked/unchecked boxes
        let sync_table_on_checkbox_change = function(){
            $checkbox_node_list.map(cb => cb.childtable_update_triggered());
        }
        // Add changehandler to $boxes_div
        $boxes_div.sync_table_on_checkbox_change = sync_table_on_checkbox_change;

        function get_child_table_entries(frm) {
            return typeof(frm.doc[child_table]) === "undefined" ? [] : frm.doc[child_table].map(i => i.title);
        }
    
        function on_master_toggle_change(is_checked) {
            if(is_checked){
                $boxes_div.hide();
                $tbl.show();
                // make sure all current checked cboxes are added to child table 
                $boxes_div.sync_table_on_checkbox_change();
            } else {
                $boxes_div.show();
                $tbl.hide();
                //if master toggle is unchecked, reset the table entries to the checked cboxes only
                const before_length = frm.doc[child_table].length;
                frm.doc[child_table] = frm.doc[child_table].filter(i => toggles.includes(i.title));
                frm.fields_dict[child_table].refresh();
                // refresh_field(child_table);
                if(frm.doc[child_table].length !== before_length){
                    frm.dirty();
                }
            }
        }

        //Checkbox state handler is called on save
        function get_master_toggle_state(title, wrapper) {
                /* Check if the default state of the master toggle is active
                   This is the case when there are extra entries in the child table,
                   that are not in the list of toggles.
                */
                let has_extra_table_entries = without(toggles, get_child_table_entries(frm)).length > 0;
                // toggle checkbox and show table or toggle based on if table has extra entries
                on_master_toggle_change(has_extra_table_entries)
                //return value deterimines the checked state of the master toggle checkbox
                return has_extra_table_entries
        }
        
        let master_toggle = pcg_web.ui.checkbox.make_checkbox(doctype, __("Specify more than required"), on_master_toggle_change, get_master_toggle_state)   
        let clearfix = $(`<div class="clearfix">
			  <label class="control-label" style="padding-right: 0px;"></label>
			 </div>`).insertAfter($boxes_div);
        $(master_toggle).insertAfter(clearfix);
        
    }

    function without(those, in_these){
        // Utility function: returns all the elements from `in_these` without the ones in `those`
        let result = [];
        in_these.map(i => {
            if (!those.includes(i) && !result.includes(i)){
                result.push(i);
            }
        })
        return result;
    }

    return {
        render_as_toggable: render_as_toggable,
    }
})();

