frappe.provide("pcg_web.utils");

let modify_quick_enties = {
    "PCG Subscription": function(dialog) {
        dialog.get_field("customer_link").$input.on('focusout', function(e){pcg_web.utils.add_search_filter(dialog)})
    }
}

let old_quick_entry = frappe.ui.form.make_quick_entry;

let new_quick_entry = (doctype, after_insert, init_callback, doc) => {
    let test = old_quick_entry(doctype, after_insert, init_callback, doc);
    test.then(dialog_wrapper => {
        let doctype_quickentry_handler = modify_quick_enties[dialog_wrapper.doc.doctype]
        if (typeof(doctype_quickentry_handler) === "function"){
            doctype_quickentry_handler(dialog_wrapper.dialog);
        }
    });
    return test;
};

frappe.ui.form.make_quick_entry = new_quick_entry;