frappe.provide("pcg_web.ui");


const renderContent = (content) => {
  if (window.frappe.ui.misc.about) {
    content.about_title &&
      (window.frappe.ui.misc.about.main_title = content.about_title);
    content.about_description &&
      (window.frappe.ui.misc.about.main_description =
        content.about_description);
    content.about_text &&
      (window.frappe.ui.misc.about.main_content = content.about_text);
  }
};

renderContent(frappe.boot.pcg_web.about);
