import SyncState from './SyncState.vue';
import PackingSidebar from './PackingSidebar.vue'; 

frappe.provide("pcg_web")
frappe.provide("pcg_web.utils")
frappe.provide("pcg_web.form")
frappe.provide("pcg_web.switched_links")
frappe.provide("pcg_web.form")
frappe.provide("pcg_web.shop_navigation")


frappe.provide("frappe.onboading_step_setup")
frappe.provide("frappe.workspace_menu")
frappe.provide("pcg_web.vue_comp")
pcg_web.vue_comp = SyncState;

frappe.onboading_step_setup = {
  test: function(a, b){
  },
  "Add stock to your items": function (a, b){
  }
}

export default {
  components: {
    SyncState
  }
}
pcg_web.sync_state = SyncState;
pcg_web.packing_sidebar = PackingSidebar;

function showEncryptedFields(frm) {
  Object.entries(frm.fields_dict).forEach(([fieldname, field]) => {
    if (!fieldname.startsWith("enc_")) {
      return;
    }
    if (field.value && field.value.startsWith("ENC-")) {
      setTimeout(() => {
        field.set_input(__("<click-to-view>"));
      }, 1000);
    }
    let field_handle = field.df.read_only ? field.$wrapper.find(".control-value") : field.$input;
    field_handle.off("click").on("click", async () => {
      if (frm.doc[fieldname] && frm.doc[fieldname].startsWith("ENC-")) {
        field.set_input(__("decrypting..."));
        try {
          const { message } = await frappe.call("pcg_web.utils.document.call_fn_decrypt", {fieldval: frm.doc[fieldname]});
          field.set_input(message);
        } catch (error) {
          field.set_input(__("error while decrypting"));
        }
      }
    });
  });
}



function restart_Tutorial(obj){
  obj.page.add_menu_item(__('Start Tutorial'), () => {
    frappe.call("pcg_web.utils.tutorial.reset_tutorial", {"workspace": localStorage.current_workspace}).then(r => {
      window.location.reload();
    });
  }, 1);
}

frappe.workspace_menu = {
  "Warehouse": restart_Tutorial,
  "Customers": restart_Tutorial,
  "Shop": restart_Tutorial,
  "Planning": restart_Tutorial,
  "Purchasing": restart_Tutorial
}

frappe.onboading_step_cleanup = {
  test: function(a, b){
  },
  "Add stock to your items": function (a, b){
  }
}

pcg_web.form_tour = (function(){
  return {
    custom_step: function(step_generator){
      return {custom_driver: function(frm, driver, on_finish){ return step_generator(frm, driver, on_finish) }};
    },
    save_and_finish_after_step: function(step){
      return {
        custom_driver: function(frm, driver, on_finish){
          let field = frm.get_docfield(step.fieldname);
          return {
            element: `.frappe-control[data-fieldname='${step.fieldname}']`,
            popover: {
              title: step.title || field.label,
              description: step.description,
              position: step.position || 'bottom'
            },
            onNext: () => {
              const next_condition_satisfied = frm.layout.evaluate_depends_on_value(step.next_step_condition || true);
              if (next_condition_satisfied) {
                frm.save("Save", (r) => {}, undefined, (r) => {

                }, true).then(r => {
                  on_finish && on_finish();
                }).catch(reaseon => {
                  driver.reset();
                  frappe.msg_dialog.onhide = function(){
                    driver.start();
                  }                });
              }
              driver.preventMove();


            }
          };
        }
      }
    },
    custom_onNext: function(step, onNext){
      return {
        custom_driver: function(frm, driver, on_finish){
          let field = frm.get_docfield(step.fieldname);
          return {...{
            element: `.frappe-control[data-fieldname='${step.fieldname}']`,
            popover: {
              title: step.title || field.label,
              description: step.description,
              position: step.position || 'bottom'
            },
            onNext: () => {
              onNext(frm, driver, on_finish)
            }
          }, ...step};
        }
      }
    },

    save_doc: function(step){
      return pcg_web.form_tour.custom_onNext(step, (frm, driver, on_finish) => {
        const next_condition_satisfied = frm.layout.evaluate_depends_on_value(step.next_step_condition || true);
        if (!next_condition_satisfied) {
          driver.preventMove();
        }
        driver.preventMove();
        frm.save().then(r => {driver.moveNext()});
      })
    }
  }
})();

pcg_web.shop_navigation.fetch_title = function(frm){
  let navlink = frm.fields_dict["navigation_link"].get_value();
  let navtype = frm.fields_dict["navigation_type"].get_value();
  const set_val = function(val){
    if (frm.fields_dict["name"].disp_status === "Write"){
      frm.fields_dict["name"].set_value(val)
    }
  };
  switch (navtype){
    case "Navigation":
      break;
    case "Website":
      break;
    case "PCG Item Group":
      frappe.db.get_value(navtype, navlink, "pcg_item_group_name").then(r => {
        set_val(r.message.pcg_item_group_name)
      })
      break;
    default:
      frappe.db.get_value(navtype, navlink, "title").then(r => {
        set_val(r.message.title)
      });
  }
};

$("body").addClass(frappe.boot.user.roles.map(r => "role_" + r.replaceAll(" ", "_").toLowerCase()).join(" "));

if(!frappe.boot.pcg_web.pcg_shop_sync_enabled){
  $("body").addClass("pcg_shop_not_enabled")
}

function add_route_change_handler(handler){
  // AnimationFrame idea from https://supersami.medium.com/detect-react-route-change-in-vanilla-js-f794ab8adca5
  let route = frappe.get_route();
  document.body.addEventListener('click', ()=>{
    requestAnimationFrame(()=>{
      let new_route = frappe.get_route();
      if(route !== new_route){
        handler(new_route);
        route = frappe.get_route()
      }
    });
}, true);

}

// overrides frappe.utils.utils
frappe.utils.map_defaults = {
  center: [51.0346722,13.6881009],
  zoom: 13,
  tiles: 'https://oekobox-online.eu/v3/tile/{z}/{x}/{y}.png',
  options: {
    attribution:
      '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  },
}
/*
frappe.ui.form.ControlGeolocation.prototype.make_map = function() {
  //this overwrites the make_map function for pcg delivery address so it doesnt use location services,
  //located here because on "setup: " call was to late
  
  

  this.bind_leaflet_map();
  this.bind_leaflet_draw_control();
  this.locate_control = {start: function() {}};
}
*/
let add_sanitized_route_to_body = function(route){
  let prefix = "pcgposition_";
  if(![undefined, null, ""].includes(route)){
    let position_css = prefix + route[0].toLowerCase() + "_" + route[1].replaceAll(" ", "_").toLowerCase();

    $("html").removeClass(function (index, className){
      let prefix_match_regex = new RegExp(`(^|\\s)${prefix}\\S+`, "g")
      return (className.match(prefix_match_regex) || []).join(' ');
    });

    $("html").addClass(position_css);

  }
}
// add_route_change_handler(add_sanitized_route_to_body);

function on_load_route(){
  if (!["", undefined, null].includes(frappe.get_route())){
    add_sanitized_route_to_body(frappe.get_route());
  } else {
    setTimeout(on_load_route, 500)
  }
}
// on_load_route();
// let original_after_ajax = frappe.after_ajax;
// frappe.after_ajax = function(fn){
//   original_after_ajax(fn);
// }


const get_time_since_last_import = function(last_import){
  //console.log("Updating import time", last_import);
  if ([undefined, null, ""].includes(last_import)){
    return [0, "error"]
  }
  const last_import_time = new Date(last_import);
  let now_time = new Date();
  let queue_state = "ok";
  // let hours_time = "";
  // let hours_since_last_import = parseInt(Math.abs(last_import_time - now_time) / (1000 * 60 * 60) % 24);
  let minutes_since_last_import = parseInt(Math.abs(last_import_time.getTime() - now_time.getTime()) / (1000 * 60)); // % 60);
  if (minutes_since_last_import > 15){
    queue_state = "warn";
    // hours_time = hours_since_last_import + __("h");
  }
  if (minutes_since_last_import > 120){
    queue_state = "error";
  }

  let time_since_last_import = minutes_since_last_import ? minutes_since_last_import : 0
  return [time_since_last_import, queue_state];
};

pcg_web.get_time_since_last_import  = get_time_since_last_import;




const queue_update = function(amount, error){
    $("#queue-counter").find("a").remove()
    if (frappe.boot.pcg_web.pcg_shop_sync_enabled){
      let state  = "warn";
      if (amount === 0){
        state = "ok";
      }
      if (error){
        state = "error";
      };
      let style = ""
      $(`<a class='notifications-icon export-update pcg-${state}' title='${__("Scheduled export events")}' href='/app/pcg-event-queue'><span style='` + style + "' >" + amount + "</span></a>").appendTo($("#queue-counter"));
    }
};


const import_update = function(amount, state){
  //console.log(`called import update with ${amount} and ${state}`);
  $("#import-time").find("a").remove()
  if (frappe.boot.pcg_web.pcg_shop_sync_enabled){
    let style = ""
    $(`<a class='notifications-icon import-update pcg-${state}' title='${__("Minutes since last shop-import")}' href='/app/pcg-event-queue'><span style='` + style + "' >" + amount + "</a></span>").appendTo($("#import-time"));
  }
};

const pcg_job_counter_update = function(amount){
  let state  = "warn";
  let style = "";
  if (amount === 0){
    state = "ok";
  }
  $("#pcg_job-counter").find("a").remove()
  $(`<a class='notifications-icon import-update pcg-${state}' title='${__("Running PCG Jobs")}' href='/app/pcg-job-log'><span style='` + style + "' >" + amount + "</a></span>").appendTo($("#pcg_job-counter"));
};


$(document).on("toolbar_setup", function(e){
  setInterval(() => import_update(...(get_time_since_last_import(frappe.boot.pcg_web.last_import_time))), 60000);

let pcg_job_counter_display =  $(`<li class='dropdown' style='margin: auto; margin-left: 5px;' id="pcg_job-counter"></li>`).insertAfter($(".dropdown-notifications"));
let import_display =  $(`<li class='dropdown' style='margin: auto; margin-left: 5px;' id="import-time"></li>`).insertAfter($(".dropdown-notifications"));
let queue_display =  $(`<li class='dropdown' style='margin: auto; margin-left: 5px;' id="queue-counter"></li>`).insertAfter($(".dropdown-notifications"));

  import_update(...get_time_since_last_import(frappe.boot.pcg_web.last_import_time));
  queue_update(frappe.boot.pcg_web.pending_queue_events)
  pcg_job_counter_update(frappe.boot.pcg_web.pcg_job_counter);


  // let myComp = new Vue({
 //    el: $(".navbar-nav")[0],
 //    render: h => h(SyncState, {
 //      props: {
 //        amount: 5,
 //      }
 //    })
 //  });
 // import SyncState from './SyncState.vue';
 // window.myComp = SyncState;


})




let global_realtime_eventhandler = {
  pcg_pending_queue_events: function(amount_error){
    let amount = amount_error[0];
    let error = amount_error[1];
    queue_update(amount, error)
  },
  pcg_shop_sync_enabled: (msg) => {
    if(!msg.is_shop_sync_enabled){
      $("body").addClass("pcg_shop_not_enabled");
    }
    else{
      $("body").removeClass("pcg_shop_not_enabled");
      frappe.boot.pcg_web.pcg_shop_sync_enabled = msg["is_shop_sync_enabled"];
      frappe.boot.pcg_web.shop_uri = msg["shop_uri"];
      queue_update(frappe.boot.pcg_web.pending_queue_events);
    }
  },
  pcg_show_toast: (msg) => {
    frappe.show_alert(msg);
  }
}

global_realtime_eventhandler["last_import_time_update"] = (updatetime) => {
  frappe.boot.pcg_web.last_import_time = updatetime;
  import_update(...get_time_since_last_import(frappe.boot.pcg_web.last_import_time));
}

global_realtime_eventhandler["pcg_job_counter_update"] = (pcg_job_counter) => {
  frappe.boot.pcg_web.pcg_job_counter = pcg_job_counter;
  pcg_job_counter_update(frappe.boot.pcg_web.pcg_job_counter);
}

//decorate socetio init to register deskwide eventhandler after initialization
let socketio_init = frappe.socketio.init
frappe.socketio.init = function(port){
  let result = socketio_init.bind(this)(port);
  Object.keys(global_realtime_eventhandler).map(event => {
    frappe.realtime.on(event, global_realtime_eventhandler[event]);
  })
  return result;
}



const add_css_classes = function(frm){
  // $(cur_frm.fields_dict[k].wrapper).addClass(k);
  Object.keys(frm.fields_dict).map(k => {
    const class_name = "pcg_" + k;
    const field = frm.fields_dict[k];
    if (typeof(field.wrapper) !== "undefined"){
      $(field.wrapper).closest(".form-column").addClass(class_name);
      if (field.df.fieldtype === "Section Break"){
        $(field.wrapper).addClass(class_name);
      }
    }
  })
};



frappe.boot.pcg_web.doctypes.map(dt => {
  frappe.ui.form.on(dt, "onload_post_render", add_css_classes);
})


// let sm = frappe.ui.form.ScriptManager;
// frappe.ui.form.ScriptManager = Class.extend({
//
// });
//   function(){
//   let orig = new sm(arguments);
//   return orig;
// }
// const handler_getter_orig = frappe.ui.form.ScriptManager.get_handlers;
// frappe.ui.form.ScriptManager.get_handlers = function(event_name, doctype){
//   let handlers = handler_getter_orig.bind(this)(event_name, doctype)
//   handlers.push(function(frm){alert("lol")})
//   return handlers
// }
//
// const get_handler_list = frappe.ui.form.get_event_handler_list;
// frappe.ui.form.get_event_handler_list = function(dt, name){
//   return [function(frm){alert("lola")}]
// }


pcg_web.switched_links = {
  /* This is a Map of doctypes that contain Link-fields
   for which the suggestions should have a swapped title and name values.

   Each of the doctypes has to contain a mapping from the fieldname of the Link-Field
   on to the fieldname of the Linked-to doctype that should be displayed

   Alternatively an function can be given which must accept the linked to doc and the JQuery wrapper of the linkfield and must return the jquery node that should be updated (which means deleted and newly inserted) if the value changes.
   */
   "PCG Stock Correction": {
    "item_offering_link": "item_name",
  },
  "PCG Delivery Pause": {
    "customer_link": "title",
    "subscription_link": "item_name",
  },
  "PCG Order": {
    "customer_link": "title",
    "delivery_address_link": "title",
    // "ddate_link": "delivery_date"
  },
  "PCG Item": {
    "stock_link": function(linked_doc, doc_field){ return default_renderer(doc_field, linked_doc.amount + "&nbsp;" + linked_doc.unit_link) }, //"amount",
    "profile_link": "profile_title",
    "origin_profile": "profile_title",
    "producer_link": "short_code",
    "vat_link": "vat_name",
    "allergens_table": {
      "title": "display_title" // title should switch the PCG Item Label's name with its 'display_title' TODO NULL CHECK
    },
   // "association": {                // multiselect geht nicht?
   //     "profiles": "profile_title"
   // }
  },
  "PCG Subscription": {
    "customer_link": "title",
    "address_link": "tour",
    "item_link": "title"
  },
  "PCG Bundle Composition": {
    "item_link": "title",
    "bundle_link": "title"
  },
  "PCG Pricelist": {
    "items": {
      "item_link": "title"
    }
  },
  "PCG Item List": {
    "items": {
      "item_link": "title"
    }
  },
  "PCG Delivery Date": {
    "tour_link": "weekday"
  },
  "PCG Invoice": {
    "customer_link": "title"
  },
  "PCG Delivery Address": {
    "customer_link": "title",
    "tour": "weekday"
  },
  "PCG POS": {
    "pricelists": {
      "pricelist_link": "title"
    }
 },
 "PCG Booking": {
    "customer_link": "title",
    "invoice_link": "invoice_date"
 },
 "PCG Discount": {
    "discount_customer_link": "title",    
    "discount_item_link": "uom"
 }
}


// This works only if the filed is "Set Only Once"
// frappe.form.link_formatters['PCG Customer'] = function(value, doc) {
//   return "testloollolol";
//   if(doc.title && doc.title !== value) {
//       return value + ': ' + doc.title;
//   } else {
//       return value;
//   }
// }



function update_child_table_title_hint(parent_doctype, doc_field, title_field_or_renderer){
  for (const [child_link_field_fieldname, child_title_field_or_renderer] of Object.entries(title_field_or_renderer)) {
    const childtable_doctype = doc_field.df.options;
    const linked_doctype = frappe.meta.docfield_map[childtable_doctype][child_link_field_fieldname].options;
    //doc_field.grid.fields_map[child_link_field_fieldname].options;
    const child_update_fn = function(frm){ // use frm.seclected_doc
      if (frm.selected_doc.doctype === childtable_doctype){
        // Update a single childtable entry
        const linked_docname = frm.selected_doc[child_link_field_fieldname];
        const grid_row = doc_field.grid.grid_rows[frm.selected_doc["idx"] - 1];
        grid_row.$input = grid_row.wrapper.find('*[data-fieldname="' + child_link_field_fieldname + '"] .static-area');
        update_title_hint(grid_row, child_title_field_or_renderer, linked_doctype, linked_docname)
      } else {
        // update all childtable entries
        doc_field.grid.data.forEach((data, index) => {
          const linked_docname = data[child_link_field_fieldname];
          const grid_row = doc_field.grid.grid_rows[index];
          grid_row.$input = grid_row.wrapper.find('*[data-fieldname="' + child_link_field_fieldname + '"] .static-area');
          update_title_hint(grid_row, child_title_field_or_renderer, linked_doctype, linked_docname)
        });
      }

    }
    frappe.ui.form.on(parent_doctype, "refresh", child_update_fn);
    frappe.ui.form.on(childtable_doctype, child_link_field_fieldname, child_update_fn);
  }
}


Object.keys(pcg_web.switched_links).forEach(doctype => {
  const switched_link_fields = pcg_web.switched_links[doctype];
  frappe.ui.form.on(doctype, "setup", function(frm){
    for (const [link_field_fieldname, title_field_or_renderer] of Object.entries(switched_link_fields)) {
      const doc_field = frm.fields_dict[link_field_fieldname];
      if(typeof title_field_or_renderer === 'object' && title_field_or_renderer !== null){
        update_child_table_title_hint(doctype, doc_field, title_field_or_renderer);
      } else {
        const update_link_field_fn = function(frm){
          update_title_hint(doc_field, title_field_or_renderer)
        }
        frappe.ui.form.on(doctype, "refresh", update_link_field_fn);
        frappe.ui.form.on(doctype, link_field_fieldname, update_link_field_fn);
        ;
      }
    }
  })


  // for each of the fields, when their value gets changed by the user:
  for (const [link_field_fieldname, title_field_or_renderer] of Object.entries(switched_link_fields)) {

    // event_handlers[link_field_fieldname] = function(frm){

    // }
  }
  // frappe.ui.form.on(doctype, event_handlers);
});


function update_title_hint(doc_field, title_field_or_renderer, linked_doctype, linked_docname){
  // doc_field must have an $input attribute
  if (!doc_field || !doc_field.get_value) {
    // we are not linked anyway
    return
  }
  linked_doctype = linked_doctype || doc_field.get_options(); // frm.meta.fields.filter(...)[0].options
  linked_docname = linked_docname || doc_field.get_value();

  if (typeof(doc_field.title_hint) !== "undefined"){
    doc_field.title_hint.remove();
  }

  if([undefined, null, "",].includes(linked_docname)){
    return;
  }

  if (typeof(title_field_or_renderer) === "function") {
    frappe.db.get_doc(linked_doctype, linked_docname).then(linked_doc => {
      let new_node = title_field_or_renderer(linked_doc, doc_field)
      if (typeof(new_node) === "string"){
        new_node = $(new_node);
      }
      title_hint_state_handler(doc_field, new_node, linked_docname);
    })

  } else if (typeof(title_field_or_renderer) === "string") {
    frappe.db.get_value(linked_doctype, linked_docname, title_field_or_renderer).then(result => {
      const new_node = default_renderer(doc_field, result.message[title_field_or_renderer]);
      title_hint_state_handler(doc_field, new_node, linked_docname);
    })
  }
}

function long_text_renderer(linked_doc, doc_field){
  let a;
  return a;
};



function default_renderer(field, title_html){
  // If the field is in a collapsed section, it's width will always be 100...
  // so we just guess a default width for our overlay of 90px in that case
  if ([null, undefined, ""].includes(title_html)){
    return undefined;
  }

  const isEnabled = field.disp_status == "Write";
  const $fieldToUse = isEnabled ? field.$input : field.$input_wrapper;   // read only looks
  const width = Math.max(parseInt($fieldToUse.width() / 3), 90);

  const $insertion = $(`<label class="calendarweek" style="right: 25px; position: absolute; color: #999; top: 0; 
                            bottom: 0; display: inline-flex; margin: auto; align-items: center; flex-direction: row-reverse;
                            width: ${width}px; white-space: nowrap;">${title_html}</label>`)
            
  if(isEnabled){
      let isPresent = $fieldToUse.find(".calendarweek")
      if (isPresent.length > 0) {
          isPresent.html(title_html);
      } else {
          $insertion.insertAfter($fieldToUse);
      }

  } else {
    $fieldToUse.find(".control-value").append($insertion);
  }

  return $insertion;
}

const renderer_calendar_week_in_field = function (frm, field_name){
  if(frm.doc[field_name]) {
    frappe.call("pcg_base.utils.return_calendar_week", {date: frm.doc[field_name]})
    .then(r => {
      renderer_calendar_week(frm.fields_dict[field_name], r.message)
    })
  }
}

const renderer_address_one_liner_in_field = function (frm, field_name){
  if(frm.doc[field_name]) {
    frappe.call("pcg_web.pcg_customer.doctype.pcg_delivery_address.pcg_delivery_address.call_get_delivery_address_one_liner_display_name", 
            {delivery_address_name: frm.doc[field_name]})
    .then(result => {
      let field = frm.fields_dict[field_name];
      field.$input_wrapper.find(".calendarweek").remove()
      default_renderer(field, result.message.address_one_liner);
    })
  }
}

function renderer_calendar_week(field, title_html){
  // If the field is in a collapsed section, it's width will always be 100...
  // so we just guess a default width for our overlay of 90px in that case
  if ([null, undefined, ""].includes(title_html)){
    return undefined;
  }

  const isEnabled = field.disp_status == "Write";
  const $fieldToUse = isEnabled ? field.$input : field.$input_wrapper;   // read only looks
  const width = Math.max(parseInt($fieldToUse.width() / 3), 90);

  const $insertion = $(`<label class="calendarweek" style="right: 25px; position: absolute; color: #999; top: 24px; 
                            bottom: 0; display: inline-flex; margin: auto; align-items: center; flex-direction: row-reverse;
                            width: ${width}px; white-space: nowrap;">${title_html}</label>`)

  if(isEnabled){
      let isPresent = $fieldToUse.parent().find(".calendarweek")
      if (isPresent.length > 0) {
          isPresent.html(title_html);
      } else {
          $insertion.insertAfter($fieldToUse);
      }

  } else {
    $fieldToUse.find(".control-value").append($insertion);
  }

  return $insertion;
}

pcg_web.default_renderer = default_renderer;
pcg_web.renderer_calendar_week_in_field = renderer_calendar_week_in_field;
pcg_web.renderer_address_one_liner_in_field = renderer_address_one_liner_in_field;

// below renderer: color: #999; margin: 5px 0 5px 2px;


const title_hint_state_handler = function(field, jquery_node, field_value) {
  field_value = field_value || field.get_value();
  if ([undefined, null, ""].includes(jquery_node)){
    if(typeof(field.title_hint) !== "undefined"){
      field.title_hint.remove();
    }
    return;
  }
  if (typeof(field.title_hint) !== "undefined" || [undefined, null, ""].includes(field_value)){
    field.title_hint.remove();
  }
  field.title_hint = jquery_node;
  field.title_hint.click(() => { if (field.$input) field.$input.focus()} );
};


let original_setup_awesomeplete = frappe.ui.form.ControlLink.prototype.setup_awesomeplete;
frappe.ui.form.ControlLink.prototype.setup_awesomeplete = setup_awesomeplete_hacked;


function setup_awesomeplete_hacked(){
  let me = this;
  // execute the original from /apps/frappe/frappe/public/js/frappe/form/controls/link.js
  // This will setup the awesmplete selection box
  original_setup_awesomeplete.bind(this)();
  let switched_fields = pcg_web.switched_links.hasOwnProperty(this.doctype) ? Object.keys(pcg_web.switched_links[this.doctype]) : [];

  // awesomplete objects must have an 'item' function that returns the renderd html.
  // here we define our own item function, that will replace the frappe default one.
  // however we will noch change the rendering at all.
  // we only intercept the call to `this.get_item` and swap the two fields before resuming normal execution
  // of `item`
  let switched_item = function(original_item, item){
    let original_get_item = this.get_item;

    // override get_item so that it returns spwapped results.
    // It will afterwards be called from the call to original_item.
    // Inside we call the original `get_item` and then return the swapped results
    this.get_item = function(x) {
      let item = original_get_item.bind(this)(x);
        if (typeof(item) !=="undefined" && typeof(item.label) === "undefined" && typeof(item.html) === "undefined"){
          return {"description": item.value, "value": item.description}
        }
        return item;
    }

    let retrun_val = original_item.bind(this)(item);
    // the following line restores the original, THIS IS NEEDED!! xD
    this.get_item = original_get_item;
    return retrun_val;
  }
  if (this.df.fieldtype === "Link" && switched_fields.includes(this.df.fieldname)){
    // let's keep the `item` function, as we do not want to change it at all
    // we are only interested in swapping `description` and `value` in the get_item
    // but get_item is called inside of `item` so we must go around one more time
    // It may most likely be possible to override `get_item` directly, but who knows
    // in which other context it may be used inside frappe/awesomplete library code.
    // I feel that this is a safer way to not break things
  //   if(frappe.ui.form.handlers[this.doctype][this.df.fieldname].length === 0){
  //     frappe.ui.form.handlers[this.doctype][this.df.fieldname] = [];
  //   }
  //   frappe.ui.form.handlers[this.doctype][this.df.fieldname].push(function(a,b,c){
  //   })
    let original_item = this.awesomplete.item;
    this.awesomplete.item = function(item){
      return switched_item.bind(me.awesomplete)(original_item, item);
    }
  }
}

var add_button_with_save_check = function (title, callback, frm, menu){
  // This will add a button, that execute the callback code after it was clicked
  // before it does this, it checks whether the form was modified and aborts the action
  // if it was modified

  // TODO: a better version MAY be to try to save if dirty and perform if save succeeds.
  //       then warn only if save fails
  if (menu !== undefined){
    frm.add_custom_button(__(title), function () {
      if (frm.is_dirty()){
          frappe.msgprint(__("Please Save current changes before executing"));
          return;
      }
      return callback(frm);
    }, menu);
  } else {
    frm.add_custom_button(__(title), function () {
      if (frm.is_dirty()){
          frappe.msgprint(__("Please Save current changes before executing"));
          return;
      }
      return callback(frm);
    });
  }

};

function curry(fn){
  // TODO: Move to our utils
  // stolen from https://gist.github.com/mkuklis/5294248
return function(...firstArgs) {
  const argsLen = fn.length;
  let allArgs = [];

  return nextArgument(...firstArgs);

  function nextArgument(...args) {
    allArgs = allArgs.concat(args);
    return allArgs.length >= argsLen ? fn.apply(this, allArgs) : nextArgument;
  }
};
}

// from https://stackoverflow.com/questions/22015684/how-do-i-zip-two-arrays-in-javascript
let zip = (a, b) => Array.from(Array(Math.max(b.length, a.length)), (_, i) => [a[i], b[i]]);


function fromEntries(arr){
  // Given an Array of tuples, creates an object.
  // like https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/fromEntries
  // implementation from https://stackoverflow.com/questions/32002176/how-to-convert-an-array-of-key-value-tuples-into-an-object/32002302#32002302
  let obj = arr.reduce((o, [ key, value ]) => {
    o[key] = value
    return o
  }, {})
  return obj
}

function getChained(obj, path){
  let that = obj;
  let args = path; // [].slice.call(arguments); // converts arguments object to array
  return args.reduce((acc, arg) => typeof(acc) === "object" ? acc[arg] : undefined, that);
}

// Object.defineProperty(Object.prototype, "pcg_setChained", function(){
function setChained(obj, path){
  let that = obj;
  let args = path; // [].slice.call(arguments); // converts arguments object to array
  return function(value){
      args.reduce((acc, elem, idx) => {
          if (idx == args.length - 1){
              acc[elem] = value;
              return acc[elem];
          } else if( typeof(acc[elem]) === "object"){
              return acc[elem];
          } else {
              acc[elem] = {};
              return acc[elem];
              }
      }, that);
      return that;
  };
}

pcg_web.utils.getChained = getChained;
pcg_web.utils.setChained = setChained;

const WARN_COLOR = "var(--dt-orange)";
const NORMAL_COLOR = "#8d99a6";
const NORMAL_BORDER_COLOR = "var(--dt-border-color)";

const mark_and_get_incomplete_fields_set_complete_status = async function(frm){
  const clear_field_markings = function(frm) {
    frm.fields.filter((field) => field.df.fieldtype !== "Table").map(function (df) {
      $(df.wrapper).find("input").removeClass("bg-warning")  // maybe better to use `border border-warning`
    });

    frm.fields.filter((field) => field.df.fieldtype === "Table").map(function (df) {
      df.$wrapper.find(".form-grid").css({"border": "1px solid " + NORMAL_BORDER_COLOR, "border-bottom": "1px solid " + NORMAL_BORDER_COLOR});
      df.$wrapper.find(".grid-footer").css({"border": "1px solid " + NORMAL_BORDER_COLOR, "border-top": "none"});
      df.$wrapper.find(".control-label").css({"color": NORMAL_COLOR});
    });
  }

  const mark_missing_fields = function(missing_fieldnames){
    if (typeof(missing_fieldnames) === "undefined"){
      return;
    }
    let missing_fields = frm.fields.filter(f => missing_fieldnames.includes(f.df.fieldname));
      missing_fields.filter(field => field.df.fieldtype !== "Table").map(field => {
        $(field.wrapper).find("input").addClass("bg-warning");
      });
      missing_fields.filter(field => field.df.fieldtype === "Table").map(field => {
        field.$wrapper.find(".form-grid").css({"border": "1px solid " + WARN_COLOR, "border-bottom": "1px solid " + NORMAL_BORDER_COLOR});
        field.$wrapper.find(".grid-footer").css({"border": "1px solid " + WARN_COLOR, "border-top": "none"});
        field.$wrapper.find(".control-label").css({"color": WARN_COLOR}); // gets overriden: .addClass("text-warning");			});
      });
  }

  incomplete_field_labels = [];
  incomplete_doc_links = [];

  if (true){//(frm.fields_dict["status"].get_model_value() === "Incomplete"){
    const res = await frm.call("call_fn_get_incomplete_fields", {});
    incomplete_field_to_label_dict = res.message.incomplete_field_to_label_dict;
    incomplete_doc_links = res.message.incomplete_doc_links;
    falsified_field_to_label_dict = res.message.falsified_field_to_label_dict;
    const fields_to_mark = Object.keys(incomplete_field_to_label_dict).concat(Object.keys(falsified_field_to_label_dict));
    clear_field_markings(frm);
    mark_missing_fields(fields_to_mark);
    if (Object.keys(incomplete_field_to_label_dict).length > 0){
      head_link_text = __('Missing Fields:') + ' ' + Object.entries(incomplete_field_to_label_dict).map(([key, value]) => `${value} (${frm.doc[key]})`).join(', ');
      frm.dashboard.set_headline(head_link_text);
    }
    if (Object.keys(falsified_field_to_label_dict).length > 0){
      head_link_text = __('Incorecct Values:') + ' ' + Object.entries(falsified_field_to_label_dict).map(([key, value]) => `${value} (${frm.doc[key]})`).join(', ');
      frm.dashboard.set_headline(head_link_text);
    }
    if (incomplete_doc_links.length > 0){
      head_link_text = __('Missing Links:') + ' ' + incomplete_doc_links.map(field_label => __(field_label)).join(', ')
      frm.dashboard.set_headline(head_link_text);
    }
  } else {
    clear_field_markings(frm);
  }
  return {'incomplete_field_labels':incomplete_field_labels, 'incomplete_doc_links': incomplete_doc_links}
}

pcg_web.form.mark_and_get_incomplete_fields_set_complete_status = mark_and_get_incomplete_fields_set_complete_status;
pcg_web.utils.curry = curry;
pcg_web.utils.zip = zip;
pcg_web.utils.fromEntries = fromEntries;
pcg_web.utils.add_route_change_handler = add_route_change_handler;
pcg_web.form.add_button_with_save_check = add_button_with_save_check;
pcg_web.utils.set_email_suggestions_for = function(frm, dt, link_field_name){
	frm.email_doc = function(message) {
		let email_composer = new frappe.views.CommunicationComposer({
			doc: frm.doc,
			frm: frm,
			subject: __(frm.meta.name) + ': ' + frm.docname,
			recipients: "",
			attach_document_print: true,
			message: message
		});
		let doc_name = frm.doc[link_field_name];
		email_composer.dialog.fields_dict["recipients"].get_data = function(){
			frappe.call("pcg_web.utils.web_utils.get_contacts", {doctype: dt, docname: doc_name}).then(resp => {
				email_composer.dialog.fields_dict["recipients"].set_data(resp.message)
			})
		} 
	}
}

pcg_web.form.MultiSelectDialog = class MultiSelectDialog1 {
  constructor(opts) {
    /* Options: doctype, target, setters, get_query, action,
     add_filters_group, data_fields, primary_action_label */
    Object.assign(this, opts);
    var me = this;
    if (this.doctype != "[Select]") {
      frappe.model.with_doctype(this.doctype, function () {
        me.make();
      });
    } else {
      this.make();
    }
  }

  make() {
    let me = this;
    this.page_length = 20;
    this.start = 0;
    let fields = this.get_primary_fields();


    // Make results area
    fields = fields.concat([
      { fieldtype: "HTML", fieldname: "results_area" },
      {
        fieldtype: "Button", fieldname: "more_btn", label: __("More"),
        click: () => {
          this.start += 20;
          this.get_results();
        }
      }
    ]);

    // Custom Data Fields
    if (this.data_fields) {
      fields.push({ fieldtype: "Section Break" });
      fields = fields.concat(this.data_fields);
    }

    this.dialog = new frappe.ui.Dialog({
      title: __("Select Item"),
      fields: fields,
    });
    this.dialog.$wrapper.find(".modal-dialog").css({width: "900px"});  // TODO Fix, make dynamic and think of mobile

    this.$parent = $(this.dialog.body);
    this.$wrapper = this.dialog.fields_dict.results_area.$wrapper.append(`<div class="results"
      style="border: 1px solid #d1d8dd; border-radius: 3px; height: 300px; overflow: auto;"></div>`);

    this.$results = this.$wrapper.find('.results');
    this.$results.append(this.make_list_row());

    this.args = {};
    frappe.call("pcg_web.datainterface.get_item_sources").then(res => {
      this.dialog.fields_dict["search_code"].df.options = res.message.join("\n");
      this.dialog.fields_dict["search_code"].set_options();
      this.get_results();
    });

    this.bind_events();
    this.get_results();
    this.dialog.show();
  }

  get_primary_fields() {
    let fields = [];
    let columns = new Array(3);
        // Hack for three column layout
    // To add column break
    columns[0] = [
      {
        fieldtype: "Select",
        label: __("Code"),
        fieldname: "search_code"
      }

    ];
    columns[1] = [
      {
        fieldtype: "Data",
        label: __("Search"),
        fieldname: "search_term"
      }
    ];
    columns[2] = [];

    fields = [
      ...columns[0],
      { fieldtype: "Column Break" },
      ...columns[1],
      { fieldtype: "Column Break" },
      ...columns[2],
      { fieldtype: "Section Break", fieldname: "primary_filters_sb" }
    ];

    if (this.add_filters_group) {
      fields.push(
        {
          fieldtype: 'HTML',
          fieldname: 'filter_area',
        }
      );
    }

    return fields;
  }


  bind_events() {
    let me = this;
    let frm = me.target;

    this.$results.on('click', '.list-item-container', function (e) {
      if (!$(e.target).is(':checkbox') && !$(e.target).is('a')) {
        let shared_urn = $(this).data("wop-id");
        console.log(shared_urn);
        frm.fields_dict["external_link_id"].set_value(shared_urn);
        let interface_name = me.dialog.fields_dict['search_code'].get_input_value().split("->")[0];
        if (! [null, undefined, ""].includes(interface_name)){
          frm.fields_dict["upstream_data_interface"].set_value(interface_name)
          frm.fields_dict["upstream_data_interface"].set_model_value(interface_name)
        }
        me.dialog.hide();
      }
    });

    this.$parent.find('[data-fieldname="search_term"],[data-fieldname="search_code"]').on('input', () => {
      var $this = $(this);
      clearTimeout($this.data('timeout'));
      $this.data('timeout', setTimeout(function () {
        frappe.flags.auto_scroll = false;
        me.empty_list();
        me.get_results();
      }, 300));
    });
  }


  empty_list() {
    // Remove **all** items
    this.$results.find('.list-item-container').remove();
  }


  make_list_row(result = {}) {
    var me = this;
    // Make a head row by default (if result not passed)
    let head = Object.keys(result).length === 0;

    let contents = ``;
    let columns = [];
    result.shared_urn = result.code + ":" + result.public_id;

    if ($.isArray(this.setters)) {
      for (let df of this.setters) {
        columns.push(df.fieldname);
      }
    } else {
      columns = columns.concat(Object.keys(this.setters));
    }
    console.log("rendering columns " + columns)
    columns.forEach(function (column) {
      contents += `<div class="list-item__content ellipsis">
        ${
  head ? `<span class="ellipsis text-muted" title="${__(frappe.model.unscrub(column))}">${__(frappe.model.unscrub(column))}</span>`
    : (column !== "name" ? `<span class="ellipsis result-row" title="${__(result[column] || '')}">${__(result[column] || '')}</span>`
      : `<a href="${"#Form/" + me.doctype + "/" + result[column] || ''}" class="list-id ellipsis" title="${__(result[column] || '')}">
              ${__(result[column] || '')}</a>`)}
      </div>`;
    });

    let $row = $(`<div class="list-item">
      ${contents}
    </div>`);

    head ? $row.addClass('list-item--head')
      : $row = $(`<div class="list-item-container" data-wop-id="${result.shared_urn}" data-item-name="${result.name}"></div>`).append($row);

    $(".modal-dialog .list-item--head").css("z-index", 0);
    return $row;
  }

  render_result_list(results, more = 0, empty = true) {
    var me = this;
    var more_btn = me.dialog.fields_dict.more_btn.$wrapper;

    more_btn.hide();

    if (results.length === 0) return;
    if (more) more_btn.show();

    let checked = [] // this.get_checked_values();

    results
      // .filter(result => !checked.includes(result.name))
      .forEach(result => {
        me.$results.append(me.make_list_row(result));
      });

    if (frappe.flags.auto_scroll) {
      this.$results.animate({ scrollTop: me.$results.prop('scrollHeight') }, 500);
    }
  }

  get_checked_items(){
    return [];

  }

  get_results() {
    let me = this;
    let filters = this.get_query ? this.get_query().filters : {} || {};
    let filter_fields = [];

    let setter = "search_term";
    let value = me.dialog.fields_dict[setter].get_value();
    if (me.dialog.fields_dict[setter].df.fieldtype == "Data" && value) {
      filters[setter] = ["like", "%" + value + "%"];
    } else {
      filters[setter] = value || undefined;
      me.args[setter] = filters[setter];
      // filter_fields.push(setter);
    }


    // let filter_group = this.get_custom_filters();
    // Object.assign(filters, filter_group);

    let args = {
      doctype: me.doctype,
      txt: me.dialog.fields_dict["search_term"].get_value(),
      filters: filters,
      filter_fields: filter_fields,
      start: this.start,
      page_length: this.page_length + 1,
      query: this.get_query ? this.get_query().query : '',
      as_dict: 1
    };
    frappe.call({
      type: "GET",
      method: this.search_url || "pcg_web.pcg_warehouse.doctype.pcg_item.pcg_item.search_remote", // 'frappe.desk.search.search_widget',
      no_spinner: true,
      args: { "search_term": me.dialog.fields_dict["search_term"].get_value() || "%",
          "search_code": me.dialog.fields_dict["search_code"].get_value()}, // cur_frm.fields_dict["external_link_source"].get_value()}, //  me.dialog.fields_dict["search_term"].get_value() //args,
      callback: function (r) {
        let more = 0;
        me.results = [];
        if (! $.isEmptyObject(r) && r.message.length) {
          r.message.forEach(function (result) {
            result.checked = 0;
            me.results.push(result);
          });
        }
        me.render_result_list(me.results, more);
      }
    });
  }
};

frappe.ui.form.MultiSelectDialog1 = pcg_web.form.MultiSelectDialog;


async function open_mail_dialog(_context_informations, single_context = false, dialog_defaults = {}){

  const first_context_info = _context_informations[0];

  let context_doc = {};

  let customer_doc = await frappe.db.get_doc('PCG Customer', first_context_info.customer_name);

  if (first_context_info.context_name != null){
    context_doc = await frappe.db.get_doc(first_context_info.context_doctype, first_context_info.context_name);  
  }

  const communicationComposer = new pcg_web.CommunicationComposer({
    // recipients: `${_context_informations.length} ${__("Customer")}`,
    context_informations: _context_informations,
    single_context: single_context,
    single_context_doctype: dialog_defaults.context_doctype,
    single_context_name: dialog_defaults.context_name,
    doc: { 'customer': customer_doc, 'context': context_doc},
    subject: dialog_defaults.subject
  });
  
  communicationComposer.send_email = send_email.bind(communicationComposer);
  // console.log(communicationComposer)
  // console.log(communicationComposer.dialog)
  // communicationComposer.dialog.fields[1].read_only = 1;
  // communicationComposer.dialog.refresh();


  communicationComposer.success = function(args) {
    let toast_text = `<a href='/app/email-queue/'>${__("pcg_web_send_email_to_customer_success")}</a>`
      .replace("{0}", _context_informations.length)
    frappe.show_alert({message: toast_text, indicator: "green"});
  }
};

pcg_web.open_mail_dialog = open_mail_dialog;

function send_email(btn, form_values, selected_attachments, print_html, print_format) {
  const me = this;
  this.dialog.hide();

  return frappe.call({
    type: "POST",
    method:"pcg_web.pcg_customer.doctype.pcg_customer_communication.pcg_customer_communication.send_pcg_mail_to_customer_post",
    args: {
      single_context: this.single_context,
      context_informations: this.internal_context_informations,
      cc: form_values.cc,
      bcc: form_values.bcc,
      subject: form_values.subject,
      content: form_values.content,
      print_html: print_html,
      send_me_a_copy: form_values.send_me_a_copy,
      print_format: print_format,
      sender: form_values.sender,
      sender_full_name: form_values.sender
        ? frappe.user.full_name()
        : undefined,
      email_template: form_values.email_template,
      attachments: selected_attachments,
      _lang : me.lang_code,
      read_receipt:form_values.send_read_receipt,
      print_letterhead: false,
      single_context_doctype: this.single_context_doctype,
      single_context_name: this.single_context_name,
      reply_to: form_values.reply_to || undefined,
    },
    btn,
    callback(r) {
      if (!r.exc) {
        frappe.utils.play_sound("email");

        if (r.message["emails_not_sent_to"]) {
          frappe.msgprint(__("Email not sent to {0} (unsubscribed / disabled)",
            [ frappe.utils.escape_html(r.message["emails_not_sent_to"]) ]) );
        }

        if (me.frm) {
          me.frm.reload_doc();
        }

        // try the success callback if it exists
        if (me.success) {
          try {
            me.success(r);
          } catch (e) {
            console.log(e); // eslint-disable-line
          }
        }

      } else {
        frappe.msgprint(__("There were errors while sending email. Please try again."));

        // try the error callback if it exists
        if (me.error) {
          try {
            me.error(r);
          } catch (e) {
            console.log(e); // eslint-disable-line
          }
        }
      }
    }
  });
}

pcg_web.CommunicationComposer = class {
	constructor(opts) {
		$.extend(this, opts);
		if (!this.doc) {
			this.doc = this.frm && this.frm.doc || {};
		}

    this.internal_context_informations = this.context_informations;

		this.make();
	}

	async make() {
		const me = this;
    const fields = await this.get_fields()

		this.dialog = new frappe.ui.Dialog({
			title: (this.title || this.subject || __("New Email")),
			no_submit_on_enter: true,
			fields: fields,
			primary_action_label: __("Send"),
			primary_action() {
				me.send_action();
			},
			secondary_action_label: __("Discard"),
			secondary_action() {
				me.dialog.hide();
			},
			size: 'large',
			minimizable: true
		});

		$(this.dialog.$wrapper.find(".form-section").get(0)).addClass('to_section');

		this.prepare();
		this.dialog.show();

		if (this.frm) {
			$(document).trigger('form-typing', [this.frm]);
		}
	}

	async get_fields() {
    let reply_to_email_accounts = await frappe.call(
      "pcg_web.pcg_customer.doctype.pcg_customer_communication.pcg_customer_communication.get_reply_to_emails")
		const fields = [
			{
				label: __("To"),
				fieldtype: "Data",
				reqd: 0,
        read_only: 1,
				fieldname: "recipients",
			},
      (reply_to_email_accounts.message.length > 0 ?
      {
        fieldtype: "Select",
				label: __("Reply-To"),
				fieldname: 'reply_to',
				options: reply_to_email_accounts.message,
        default: reply_to_email_accounts.message[0],
      } : undefined),
			{
				fieldtype: "Button",
				label: frappe.utils.icon('down'),
				fieldname: 'option_toggle_button',
				click: () => {
					this.toggle_more_options();
				}
			},
			{
				fieldtype: "Section Break",
				hidden: 1,
				fieldname: "more_options"
			},
			{
				label: __("CC"),
				fieldtype: "MultiSelect",
				fieldname: "cc",
			},
			{
				label: __("BCC"),
				fieldtype: "MultiSelect",
				fieldname: "bcc",
			},
			{
				label: __("Email Template"),
				fieldtype: "Link",
				options: "Email Template",
				fieldname: "email_template"
			},
			{ fieldtype: "Section Break" },
			{
				label: __("Subject"),
				fieldtype: "Data",
				reqd: 1,
				fieldname: "subject",
				length: 524288
			},
			{
				label: __("Message"),
				fieldtype: "Text Editor",
				fieldname: "content"
			},
      {
        fieldtype: "HTML",
        options: `<p class="alert alert-warning">${__("The signature is added automatically")}</p>\n`
			},
			{ fieldtype: "Section Break" },
      {
				label: __("Consider advertising permission"),
				fieldtype: "Check",
				fieldname: "advertising_permission",
				default: 0
			},
      {
				label: __("Including Inactive Customer"),
				fieldtype: "Check",
				fieldname: "including_inactive_customer",
				default: 0
			},
      {
				label: __("Unique Cutomer"),
				fieldtype: "Check",
				fieldname: "unique_customer",
        description: __('pcg_web_open_mail_dialog_unique_customer_description'),
				default: 0
			},
		].filter(a => typeof(a) !== "undefined");

		return fields;
	}

	toggle_more_options(show_options) {
		show_options = show_options || this.dialog.fields_dict.more_options.df.hidden;
		this.dialog.set_df_property('more_options', 'hidden', !show_options);

		const label = frappe.utils.icon(show_options ? 'up-line': 'down');
		this.dialog.get_field('option_toggle_button').set_label(label);
	}

	prepare() {
		this.setup_subject_and_recipients();
		this.setup_email_template();
		this.setup_advertising_permission();
		this.setup_including_inactive_customer();
		this.set_values();
    this.filter_customer_names();
    this.setup_reply_to_help_bubble();
    this.setup_unique_customer();
	}

	setup_subject_and_recipients() {
		this.subject = this.subject || "";
    this.recipients=`${this.internal_context_informations.length} ${__("Customer")}`;
	}

  get_customer_names() {
    const customer_names = this.context_informations.map(_ => _.customer_name);
    return customer_names;
  }

  async set_internal_context_informations_by_customer_names(customer_names, unique_customer) {
    let context_informations = this.context_informations.filter(_ => customer_names.includes(_.customer_name));
    if (unique_customer == true){
      let temp_dict_to_make_unique = {}
      context_informations.forEach((_) => 
      temp_dict_to_make_unique[_.customer_name] = _);
      context_informations = Object.values(temp_dict_to_make_unique);
    }
    this.internal_context_informations = context_informations;
    this.recipients=`${this.internal_context_informations.length} ${__("Customer")}`;
    for (const fieldname of ["recipients", "cc", "bcc", "sender"]) {
			await this.dialog.set_value(fieldname, this[fieldname] || "");
		}
  };

	setup_advertising_permission() {
		const me = this;
		this.dialog.fields_dict["advertising_permission"].df.onchange = async function(_args) {
      if (_args.type != 'change') return;
      me.filter_customer_names();
		};
	};

  setup_including_inactive_customer() {
    const me = this;
		this.dialog.fields_dict["including_inactive_customer"].df.onchange = async function(_args) {
      if (_args.type != 'change') return;
      me.filter_customer_names();
		};
	};
  
  setup_unique_customer() {
    const me = this;
    this.dialog.fields_dict["unique_customer"].df.onchange = async function(_args) {
      if (_args.type != 'change') return;
      me.filter_customer_names();
    };
  };

  setup_reply_to_help_bubble(){
    const d = this.dialog;
    pcg.utils.toggleable_field_tooltip(d, d.fields_dict['reply_to'], __('pcg_customer_communication_reply_to_help_bubble'));
  }

  async filter_customer_names(){
    const advertising_permission = this.dialog.fields_dict.advertising_permission.get_value();
    const including_inactive_customer = this.dialog.fields_dict.including_inactive_customer.get_value();
    const unique_customer = this.dialog.fields_dict.unique_customer.get_value();
    let use_unique_customer = false;
    let customer_names = this.get_customer_names();

    const filters = {'name':['in', customer_names]}
    if (advertising_permission ){
      filters.newsletter_subscription = 1;
    }
    if (!including_inactive_customer){
      filters.status = ['!=', 'Inactive'];
    }
    if (unique_customer){
      use_unique_customer = true;
    }

    customer_names = await frappe.db.get_list('PCG Customer', { filters: filters, pluck: 'name', limit: 0 });
    this.set_internal_context_informations_by_customer_names(customer_names, use_unique_customer)
  };

  setup_email_template() {
		const me = this;

		this.dialog.fields_dict["email_template"].df.onchange = async function() {
			const email_template = me.dialog.fields_dict.email_template.get_value();
			if (!email_template) return;

			function prepend_reply(reply) {
				if (me.reply_added === email_template) return;

        console.log(reply);

				const content_field = me.dialog.fields_dict.content;
				const subject_field = me.dialog.fields_dict.subject;

				let content = content_field.get_value() || "";

				content_field.set_value(`${reply.response}<br>${content}`);
				subject_field.set_value(reply.subject);

				me.reply_added = email_template;
			}

      let email_template_doc = await frappe.db.get_doc("Email Template", email_template);
      prepend_reply(email_template_doc);
		};
	}

	async set_values() {
		for (const fieldname of ["recipients", "cc", "bcc", "sender"]) {
			await this.dialog.set_value(fieldname, this[fieldname] || "");
		}

		const subject = frappe.utils.html2text(this.subject) || '';
		await this.dialog.set_value("subject", subject);

		await this.set_content();

		// set default email template for the first email in a document
		if (this.frm && !this.is_a_reply && !this.content_set) {
			const email_template = this.frm.meta.default_email_template || '';
			await this.dialog.set_value("email_template", email_template);
		}

		for (const fieldname of ['email_template', 'cc', 'bcc']) {
			if (this.dialog.get_value(fieldname)) {
				this.toggle_more_options(true);
				break;
			}
		}
	}

	send_action() {
		const me = this;
		const btn = me.dialog.get_primary_btn();
		const form_values = this.get_values();
		if (!form_values) return;

		const selected_attachments =
			$.map($(me.dialog.wrapper).find("[data-file-name]:checked"), function (element) {
				return $(element).attr("data-file-name");
			});


		if (form_values.attach_document_print) {
			me.send_email(btn, form_values, selected_attachments, null, form_values.select_print_format || "");
		} else {
			me.send_email(btn, form_values, selected_attachments);
		}
	}

	get_values() {
		const form_values = this.dialog.get_values();

		// cc
		for (let i = 0, l = this.dialog.fields.length; i < l; i++) {
			const df = this.dialog.fields[i];

			if (df.is_cc_checkbox) {
				// concat in cc
				if (form_values[df.fieldname]) {
					form_values.cc = ( form_values.cc ? (form_values.cc + ", ") : "" ) + df.fieldname;
					form_values.bcc = ( form_values.bcc ? (form_values.bcc + ", ") : "" ) + df.fieldname;
				}

				delete form_values[df.fieldname];
			}
		}

		return form_values;
	}

	send_email(btn, form_values, selected_attachments, print_html, print_format) {
	}

	async set_content(sender_email) {
		if (this.content_set) return;

		let message = this.message || "";
		if (!message && this.frm) {
			const { doctype, docname } = this.frm;
			message = await localforage.getItem(doctype + docname) || "";
		}

		if (message) {
			this.content_set = true;
		}

		await this.dialog.set_value("content", message);
	}

	html2text(html) {
		// convert HTML to text and try and preserve whitespace
		const d = document.createElement( 'div' );
		d.innerHTML = html.replace(/<\/div>/g, '<br></div>')  // replace end of blocks
			.replace(/<\/p>/g, '<br></p>') // replace end of paragraphs
			.replace(/<br>/g, '\n');

		// replace multiple empty lines with just one
		return d.textContent.replace(/\n{3,}/g, '\n\n');
	}
};


// https://stackoverflow.com/questions/4974238/javascript-equivalent-of-pythons-format-function
String.prototype.format = function () {
  var i = 0, args = arguments;
  return this.replace(/{\d+}/g, function () {
    return typeof args[i] != 'undefined' ? args[i++] : '';
  });
};

function disableCustomButton(_date_label){
	$(`button:contains('${_date_label}')`).prop('disabled', true);
}

function enabledCustomButton(_date_label){
	$(`button:contains('${_date_label}')`).prop('disabled', false);
}

function disableDragAndDropForRows(_rows){
  for (let i = 0; i < _rows.length; i++) {
    _rows[i]._sortable = false;
  } 
}

async function open_bnn_fields_dialog(defaults = {}, fn_primary_action) {

  let d = new frappe.ui.Dialog({
    title: __("Import BNN"),
    fields: [
      {
        fieldname: "bnn_encoding_int",
        fieldtype: "Int",
        label: __("Encoding"),
        default: defaults.bnn_encoding_int
      },
      {
        fieldname: "valid_from",
        fieldtype: "Date",
        label: __("Vaild From"),
        default: defaults.valid_from
      },
      {
        fieldname: "valid_until",
        fieldtype: "Date",
        label: __("Vaild Until"),
        default: defaults.valid_until
      },
      {
        label: __('Vendor'),
        fieldname: "vendor_link",
        fieldtype: "Link",
        options: "PCG Vendor",
        default: defaults.vendor_link,
        reqd: 1
      },
      {
        label: __('Import Name'),
        fieldname: 'import_name',
        fieldtype: 'Data',
        reqd: 1,
        default: defaults.import_name,
        length: 6
      },
      {
        label: __('pcg_item_offering_list_import_cleanup_checkbox'),
        fieldname: 'cleanup',
        default: defaults.cleanup,
        fieldtype: 'Check'
      }
    ],
    primary_action_label: 'Import',
    primary_action(values){
      d.hide();
      fn_primary_action(values);
    }
  });
  //Add Help-bubbles
  pcg.utils.toggleable_field_tooltip(d, d.fields_dict['cleanup'], __('pcg_item_offering_list_import_cleanup_checkbox_help'));
  pcg.utils.toggleable_field_tooltip(d, d.fields_dict['import_name'], __('pcg_item_offering_list_import_import_name_help'));
  pcg.utils.toggleable_field_tooltip(d, d.fields_dict['bnn_encoding_int'], "0=AscII, 1=Ansi");
  d.show();
}


pcg_web.disableCustomButton = disableCustomButton;
pcg_web.open_bnn_fields_dialog = open_bnn_fields_dialog;
pcg_web.enabledCustomButton = enabledCustomButton;
pcg_web.disableDragAndDropForRows = disableDragAndDropForRows;
pcg_web.showEncryptedFields = showEncryptedFields;
pcg_web.get_diff = async function(frm){
  return await frappe.call("pcg_base.utils.get_frm_diff", {doc: frm.doc})
}

pcg_web.map_object_values_to_object = function(listOfObjects, key_or_getter) {
  return listOfObjects.reduce((result, obj) => {
      let value; 
      if(typeof(key_or_getter) === 'function'){
        value = key_or_getter(obj);
      } else {
        value = obj[key_or_getter];
      }
      result[value] = result[value] || [];
      result[value].push(obj);
      return result;
  }, {})
}

function generateUUID() {
    // Create an array with 16 bytes
    const array = new Uint8Array(16);
  
    // Fill the array with cryptographically secure random values
    crypto.getRandomValues(array);
    
    // Set the version to 4 (random UUID)
    array[6] = (array[6] & 0x0f) | 0x40;
    
    // Set the variant to RFC 4122
    array[8] = (array[8] & 0x3f) | 0x80;
    
    // Convert the array to a UUID string
    const uuid = Array.from(array)
      .map((byte, index) => {
        // Add dashes at the appropriate positions
        const hex = byte.toString(16).padStart(2, '0');
        if (index === 4 || index === 6 || index === 8 || index === 10) {
          return '-' + hex;
        }
        return hex;
      })
      .join('');
    return uuid; 
}

pcg_web.mass_operation_or_singleton_action_dialog = function(dialog_opts, listview_or_docname, answer_function){
  // listview_or_docname will determine, whether this should be a single operation or a mass action
  // the primary action must handle the difference, this will only make the follwing difference:
  //
  // - if a listview is given for listview_or_docname the filters argument to the primary action will be the actual filters, 
  // - if a docname is given for listview_or_docname the filters will be [["name", "=", "<given_docname>"]]
  // - if nothing is given for listview_or_docname, filters will be [] and the primary action must give the docname itself
  
  let filters = [];
  let listview_filters = [];
  let dialog;
  let expected_total_count = 1;

  const updateItemCount = function(targetNode, lenItems) {
    let divContent = __("Apply for {0} elements", [lenItems]);
    let divHtml = '<div class="itemCount">' + divContent + '</div>';
    // Check if a div already exists after the targetNode
    let existingDiv = $(targetNode).parent().find(".itemCount");
    // If a div exists, replace its content with the new text; otherwise, append a new div
    if (existingDiv.length > 0) {
      existingDiv.text(divContent);
    } else {
      $(targetNode).after(divHtml);
    }
  };
  if (listview_or_docname !== null && typeof listview_or_docname !== 'undefined') {
    // Check if listview_or_docname is a string
    if (typeof listview_or_docname === 'string') {
      filters = [["name", "=", listview_or_docname]];
    }
    // Check if listview_or_docname is an object
    else if (typeof listview_or_docname === 'object') {
      const ALL_EXISTING = __("All existing");
      const ALL_CHECKED = __("All checked");
      const ALL_FILTERED = __("All in filter");
      let listview = listview_or_docname;
      listview_filters = listview.get_filters_for_args();
      filters = listview_filters;
      let checked_elements = listview.get_checked_items().map(i => i.name);
      let filtered_option = filters ? [ALL_FILTERED] : []
      let checked_elements_option = checked_elements ? [ALL_CHECKED] : [] // listview.get_checked_items().map(i => i.name);
      const defaults = checked_elements.length > 0 ? checked_elements_option : (filters ? filtered_option : __("All existing"));
      let ask_for_all_or_filterd_field = {
        fieldname: 'apply_for_all_checkbox',
        label: __('Apply for'),
        fieldtype: 'Select',
        options: [ALL_EXISTING].concat(filtered_option).concat(checked_elements_option),
        default: defaults,
        change: async function(event){
          let apply_for = $(event.target).val(); //  prop('checked');
          switch(apply_for){
            case ALL_EXISTING:
              let count = await frappe.call("pcg_web.utils.web_utils.get_element_count", {doctype: listview.doctype})
              updateItemCount(dialog.$body, count.message); 
              filters = [];
              expected_total_count = count;
              break;
            case ALL_CHECKED:
              updateItemCount(dialog.$body, checked_elements.length); 
              filters = [[listview.doctype, "name", "IN", checked_elements]];
              expected_total_count = checked_elements.length;
              break;
            case ALL_FILTERED:
              filters= listview_filters;
              updateItemCount(dialog.$body, cur_list.total_count);
              expected_total_count =  listview.total_count || 0;
              break;
          }
        },
      }
      dialog_opts["fields"].push(ask_for_all_or_filterd_field);
    }
  }
  const orig_primary_action = dialog_opts['primary_action'];
  const event_uuid = generateUUID();
  dialog_opts['primary_action'] = (values) => {
    return orig_primary_action(values, filters, expected_total_count, event_uuid);
  }

  dialog = new frappe.ui.Dialog(dialog_opts);
  updateItemCount(dialog.$body, expected_total_count);
  if(dialog.fields_dict.apply_for_all_checkbox){
    dialog.fields_dict.apply_for_all_checkbox.$input.change() // to update to the correct item count;
  }

  const answer_function_wrapper_with_unregister = function(data){
    frappe.realtime.off(event_uuid);
    answer_function(data);
  }

  frappe.realtime.on(event_uuid, answer_function_wrapper_with_unregister);

  return dialog;
}

