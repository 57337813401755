frappe.provide("pcg_web.price_utils")

pcg_web.price_utils = (function() {
    //answers for item pricelogic
    function answer_prices(res) {
        if (res.message.status === "success"){
        frappe.msgprint(__('increase_price_success'));
        } else if(res.message.status === "error") {
        let items = res.message.items.length !== 0 ? res.message.items.join(', ') : "";
        let negativ =  res.message.negativ.length !== 0 ? res.message.negativ.join(', ') : "";
        if (items && !negativ) {
            frappe.throw(__('items_without_vendor {0}', [items]));
        } else if (!items && negativ) {
            frappe.throw(__('items_with_negativ {0}', [negativ]))
        } else if (items && negativ) {
            frappe.throw(__('items_negativ_and_vendor {0} {1}', [items, negativ]))
        }
        } else {
        frappe.throw(__('An error occured.'));
        }
    }

    return {
        answer_prices: answer_prices,
    }

})()
