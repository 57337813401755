<script>
export default {
  props: ["messagejo", "order_details", "amount", "greeting", "posts"],
  name: "sync-state",
  methods: {
    focusout: function(){
      debugger;
    }
  }
}
</script>

<template>
<div>
  <p class="greeting">propamout: {{ amount }} my awdwadwdfried says: {{ greeting }} </p>
<table class="table table-light">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Item</th>
        <th scope="col">Amount</th>
        <th scope="col">Unit</th>
        <th scope="col">Handle</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="od in order_details" v-bind:key="od.name">
        <th scope="row" >{{ od.idx }}</th>
        <td>{{ od.item_name }}</td>
        <td>{{ od.amount }}</td>
        <td>{{ od.unit_link }}</td>
        <td style="display: flex">
          <input type="text" v-on:focusout="focusout" step="0.01" class="form-control input-xs" maxlength="20" >
          </td>
      </tr>
    </tbody>
  </table>
  </div>
</template>

<style>
.greeting {
  color: blue;
  font-weight: bold;
}
</style>