frappe.provide("pcg_web.stock_utils")

pcg_web.stock_utils = (function() {

    //dialog for stock correction logic
    function new_stock_correction(frm, callback) {
        let options = [__('pcg_stock_correction_option_stocktaking'), __('pcg_stock_correction_option_incoming_goods')]

        let d = new frappe.ui.Dialog({
        title: __('pcg_offerings_stock_correction'),
        fields: [
            {
            label: __('New Stock'),
            fieldname: 'new_stock',
            fieldtype: 'Data',
            reqd: 1
        },
        {
            label: __('Reason'),
            fieldname: 'reason',
            fieldtype: 'Select',
            options: ['pcg_stock_correction_option_stocktaking', 'pcg_stock_correction_option_incoming_goods'],
            reqd: 1
        },
        {
            label:__('Note'),
            fieldname: 'note',
            fieldtype: 'Text'
        }
        ],
        primary_action_label: 'Submit',
        primary_action(values) {
            callback(values) 
            d.hide();
        }
        });
        
        d.show();
        pcg_web.default_renderer(d.fields_dict.new_stock, frm.doc.unit_of_stock_link);
        pcg.utils.toggleable_field_tooltip(d, d.fields_dict.new_stock, "pcg_stock_correction_help");
    }

    return {
        new_stock_correction: new_stock_correction
    }

})()