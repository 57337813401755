<script>
export default {
  props: ["lol"],
  name: "sidebar",
  data: function() {
    return {
      current: 0,
      orders: []
    }
  }
}
</script>

<template>
  <p class="greeting">my fried says: {{ current }} x {{ orders }} and {{ lol }}</p>
</template>

<style>
.greeting {
  color: blue;
  font-weight: bold;
}
</style>